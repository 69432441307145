<msa-card [menuItems]="menuItems" cardTitle="i18n.person-data.communication.text">
  <div class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-pb-30 [&_*]:tw-border-black-light-3">
    @if (phoneNumbersByType()) {
      <dl class="msa-text tw-grid tw-content-start d:tw-gap-y-18 d:tw-border-e d:tw-pe-30">
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!phoneNumbersByType().CELL.number"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.phone-numbers.mobile-private" | safeTranslate }}
          </dt>
          <dd class="tw-flex tw-gap-12">
            {{ phoneNumbersByType().CELL.number }}
            @if (phoneNumbersByType().CELL.preferred) {
              <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
            }
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-18 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!phoneNumbersByType().CELP.number"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.phone-numbers.mobile-business" | safeTranslate }}
          </dt>
          <dd class="tw-flex tw-gap-12">
            {{ phoneNumbersByType().CELP.number }}
            @if (phoneNumbersByType().CELP.preferred) {
              <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
            }
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-18 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!phoneNumbersByType().HOME.number"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.phone-numbers.private" | safeTranslate }}
          </dt>
          <dd>
            {{ phoneNumbersByType().HOME.number }}
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-18 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!phoneNumbersByType().BUSN.number"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.phone-numbers.business" | safeTranslate }}
          </dt>
          <dd>
            {{ phoneNumbersByType().BUSN.number }}
          </dd>
        </div>
      </dl>
    }
    @if (emailsByType()) {
      <dl class="msa-text tw-grid tw-content-start d:tw-gap-y-18 d:tw-ps-30">
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-18 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!emailsByType().HOME.address"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.emails.private" | safeTranslate }}
          </dt>
          <dd class="tw-flex tw-gap-12">
            {{ emailsByType().HOME.address }}
            @if (emailsByType().HOME.preferred) {
              <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
            }
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-18 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!emailsByType().BUSN.address"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.emails.business" | safeTranslate }}
          </dt>
          <dd class="tw-flex tw-gap-12">
            {{ emailsByType().BUSN.address }}
            @if (emailsByType().BUSN.preferred) {
              <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
            }
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-18 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2"
          [class.max-d:tw-hidden]="!emailsByType().OTHR.address"
        >
          <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
            {{ "i18n.person-data.communication.emails.other" | safeTranslate }}
          </dt>
          <dd class="tw-flex tw-gap-12">
            {{ emailsByType().OTHR.address }}
            @if (emailsByType().OTHR.preferred) {
              <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
            }
          </dd>
        </div>
        @if (communicationLanguage()) {
          <div class="tw-grid tw-grid-cols-1 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
            <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
              {{ "i18n.profile-settings-title.user.correspondence.language" | safeTranslate }}
            </dt>
            <dd class="tw-flex tw-gap-12">
              {{ getCommunicationLanguageTranslation(communicationLanguage()!) | safeTranslate }}
            </dd>
          </div>
        }
      </dl>
    }
  </div>
  <ng-container *appFeatureFlag="'languageSkills'">
    @if (languageSkills()) {
      <div class="msa-text tw-grid tw-grid-cols-5 tw-items-end tw-border-black-light-3 tw-py-18 d:tw-grid-cols-14">
        <div class="tw-col-span-2 tw-text-black-light-5.5 max-d:tw-text-xs max-d:tw-leading-xs d:tw-col-span-3">
          {{ "i18n.person-data.communication.table.language-skills" | safeTranslate }}
        </div>
        <div class="tw-flex tw-justify-center tw-text-black-light-5.5 max-d:tw-hidden d:tw-col-span-2">
          {{ "i18n.person-data.communication.table.mother-tongue" | safeTranslate }}
        </div>
        <div
          class="tw-col-span-1 tw-text-black-light-5.5 max-d:tw-text-center max-d:tw-text-xs max-d:tw-leading-xs d:tw-col-span-3"
        >
          {{ "i18n.person-data.communication.table.speaking" | safeTranslate }}
        </div>
        <div
          class="tw-col-span-1 tw-text-black-light-5.5 max-d:tw-text-center max-d:tw-text-xs max-d:tw-leading-xs d:tw-col-span-3"
        >
          {{ "i18n.person-data.communication.table.reading" | safeTranslate }}
        </div>
        <div
          class="tw-col-span-1 tw-text-black-light-5.5 max-d:tw-text-center max-d:tw-text-xs max-d:tw-leading-xs d:tw-col-span-3"
        >
          {{ "i18n.person-data.communication.table.writing" | safeTranslate }}
        </div>
      </div>
      @for (skill of languageSkills(); track $index) {
        <div
          class="msa-text tw-grid tw-grid-cols-5 tw-border-t tw-border-black-light-3 tw-py-9 last:max-d:tw-border-b d:tw-grid-cols-14 d:tw-py-18"
          data-cy="languageSkillrow"
        >
          <div class="tw-col-span-2 tw-flex tw-gap-6 d:tw-col-span-3" data-cy="languageSkill">
            <span>{{ skill.language?.descriptionDto | translateObject }}</span>
            <mat-icon
              class="!tw-size-21 !tw-overflow-visible d:!tw-hidden d:!tw-size-24"
              [class.!tw-hidden]="!skill.motherTongue"
              svgIcon="circle_1"
            />
          </div>
          <div class="tw-flex tw-justify-center max-d:tw-hidden d:tw-col-span-2" data-cy="motherTongue">
            <mat-icon
              class="!tw-size-21 !tw-overflow-visible d:!tw-size-24"
              [class.!tw-hidden]="!skill.motherTongue"
              svgIcon="check"
            />
          </div>
          <div
            class="tw-col-span-1 tw-overflow-hidden tw-text-ellipsis tw-hyphens-none tw-break-keep max-d:tw-text-center max-d:tw-text-s max-d:tw-leading-s d:tw-col-span-3"
            data-cy="speaking"
          >
            {{ getLanguageSkillLevelTranslation(skill.skillLevelTalk) | safeTranslate }}
          </div>
          <div
            class="tw-col-span-1 tw-overflow-hidden tw-text-ellipsis tw-hyphens-none tw-break-keep max-d:tw-text-center max-d:tw-text-s max-d:tw-leading-s d:tw-col-span-3"
            data-cy="reading"
          >
            {{ getLanguageSkillLevelTranslation(skill.skillLevelRead) | safeTranslate }}
          </div>
          <div
            class="tw-col-span-1 tw-overflow-hidden tw-text-ellipsis tw-hyphens-none tw-break-keep max-d:tw-text-center max-d:tw-text-s max-d:tw-leading-s d:tw-col-span-3"
            data-cy="writing"
          >
            {{ getLanguageSkillLevelTranslation(skill.skillLevelWrite) | safeTranslate }}
          </div>
        </div>
      }
    }
  </ng-container>
</msa-card>
