<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="ibanForm.invalid || (!ibanForm.get('userIsAccountOwner')?.value && ownerForm.invalid)"
  [footNote]="'i18n.common.mandatory'"
  [title]="'i18n.person-data.dialogs.bank-edit.title'"
  (confirmEvent)="updateBankAccount($event)"
>
  <form class="tw-flex tw-flex-col tw-gap-30 d:tw-gap-60" [formGroup]="ibanForm">
    <msa-text-input
      id="iban"
      [ngxDropSpecialCharacters]="true"
      formControlName="iban"
      label="i18n.person-data.dialogs.bank-edit.label"
      ngxMask="AAAA AAAA AAAA AAAA AAAA A"
      hint="i18n.person-data.dialogs.bank-edit.hint"
      inputClasses="tw-uppercase"
    />
    <div class="tw-mb-15 d:tw-mb-30">
      <msa-checkbox id="isAccountOwner" formControlName="userIsAccountOwner">
        <p [class.tw-text-black-light-5]="!ibanForm.get('userIsAccountOwner')?.value">
          <span [innerHTML]="'i18n.person-data.dialogs.bank-edit.owner-checkbox' | safeTranslate"></span>&nbsp;
          <strong>{{ fullName() }}</strong>
        </p>
      </msa-checkbox>
    </div>
  </form>

  @if (!ibanForm.get("userIsAccountOwner")?.value) {
    <form
      class="tw-grid tw-grid-cols-4 tw-items-end tw-gap-x-9 tw-gap-y-9 d:tw-gap-x-12 d:tw-gap-y-18"
      [formGroup]="ownerForm"
    >
      <h4 class="msa-text-field-set tw-col-span-4">
        {{ "i18n.person-data.dialogs.bank-edit.other-owner.title" | safeTranslate }}
      </h4>
      <div class="tw-col-span-2">
        <msa-text-input
          id="firstName"
          [maxLength]="19"
          formControlName="firstName"
          label="i18n.person-data.dialogs.bank-edit.other-owner.name"
        />
      </div>
      <div class="tw-col-span-2">
        <msa-text-input id="lastName" [maxLength]="29" formControlName="lastName" />
      </div>
      <div class="tw-col-span-4">
        <msa-text-input
          id="addressLine1"
          [maxLength]="55"
          formControlName="addressLine1"
          label="i18n.person-data.dialogs.bank-edit.other-owner.addressLine1"
        />
      </div>
      <div class="tw-col-span-4">
        <msa-text-input
          id="addressLine2"
          [maxLength]="55"
          formControlName="addressLine2"
          label="i18n.person-data.dialogs.bank-edit.other-owner.addressLine2"
        />
      </div>
      <div class="tw-col-span-1">
        <msa-text-input
          id="zipCode"
          [minLength]="4"
          [maxLength]="4"
          formControlName="zipCode"
          ngxMask="9999"
          label="i18n.person-data.dialogs.bank-edit.other-owner.zip-city"
        />
      </div>
      <div class="tw-col-span-3">
        <msa-text-input id="city" [maxLength]="30" formControlName="city" />
      </div>
    </form>
  }
</msa-dialog>
