<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="phoneForm.invalid"
  [footNote]="'i18n.person-data.dialogs.phone-edit.foot-note'"
  [title]="title"
  (confirmEvent)="updatePhones($event)"
>
  <div class="msa-text tw-hidden tw-grid-cols-4 tw-text-black-light-5 d:tw-mt-45 d:tw-grid">
    <div class="tw-col-span-2 tw-col-start-2">
      {{ "i18n.person-data.dialogs.phone-edit.column-phone" | safeTranslate }}
    </div>
    <div class="tw-col-span-1">
      {{ "i18n.person-data.dialogs.phone-edit.column-preferred" | safeTranslate }}
    </div>
  </div>

  <!-- Private cellphone -->
  <form class="msa-text max-d:tw-mt-15 [&>*]:tw-border-black-light-7" [formGroup]="phoneForm">
    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18">
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="privateCellPhone">
          {{ "i18n.person-data.dialogs.phone-edit.private-cell" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input
          id="privateCellPhone"
          [cleanerRegex]="phoneRegex"
          placeholder="i18n.person-data.dialogs.phone-edit.example"
          formControlName="privateCellPhone"
        />
      </div>
      <div class="tw-col-span-1 tw-mt-9 tw-flex tw-items-center tw-py-12 d:tw-justify-center">
        <app-msa-radiobutton
          id="privateCellPhonePreferred"
          value="privateCellPhonePreferred"
          formControlName="preferredPhone"
        >
          <label class="d:tw-hidden" for="privateCellPhonePreferred">
            {{ "i18n.person-data.dialogs.phone-edit.column-preferred" | safeTranslate }}
          </label>
        </app-msa-radiobutton>
      </div>
    </div>

    <!-- Business cellphone -->
    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18">
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="businessCellPhone">
          {{ "i18n.person-data.dialogs.phone-edit.business-cell" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input
          id="businessCellPhone"
          [cleanerRegex]="phoneRegex"
          placeholder="i18n.person-data.dialogs.phone-edit.example"
          formControlName="businessCellPhone"
        />
      </div>
      <div class="tw-col-span-1 tw-mt-9 tw-flex tw-items-center tw-py-12 d:tw-justify-center">
        <app-msa-radiobutton
          id="businessCellPhonePreferred"
          value="businessCellPhonePreferred"
          formControlName="preferredPhone"
        >
          <label class="d:tw-hidden" for="businessCellPhonePreferred">
            {{ "i18n.person-data.dialogs.phone-edit.column-preferred" | safeTranslate }}
          </label>
        </app-msa-radiobutton>
      </div>
    </div>

    <!-- Private phone -->
    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18">
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="privatePhone">
          {{ "i18n.person-data.dialogs.phone-edit.private" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input
          id="privatePhone"
          [cleanerRegex]="phoneRegex"
          placeholder="i18n.person-data.dialogs.phone-edit.example"
          formControlName="privatePhone"
        />
      </div>
    </div>

    <!-- Business phone -->
    <div
      class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-b tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18"
    >
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="businessPhone">
          {{ "i18n.person-data.dialogs.phone-edit.business" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input
          id="businessPhone"
          [cleanerRegex]="phoneRegex"
          placeholder="i18n.person-data.dialogs.phone-edit.example"
          formControlName="businessPhone"
        />
      </div>
    </div>
  </form>
</msa-dialog>
