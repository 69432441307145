@if (serviceDaysData(); as serviceData) {
  @if (!serviceData.isActiveMember) {
    <msa-card
      [footNote]="serviceData.isSpecialist ? 'i18n.service-days.footer-note-body' : ''"
      [footNoteBody]="serviceData.isSpecialist ? 'i18n.service-days.footer-note-title' : ''"
      cardTitle="i18n.service-days.title"
    >
      <div class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 [&_*]:tw-border-black-light-3">
        <dl class="msa-text tw-flex tw-flex-col d:tw-me-30 d:tw-gap-y-18">
          <h3 class="msa-text-h3 max-d:tw-pb-12 max-d:tw-pt-12">
            {{ "i18n.service-days.totalMilitaryCareer" | safeTranslate }}
          </h3>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.servedDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.servedDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.totalCompletedDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2 tw-flex tw-items-center" data-cy="function">
              {{ serviceData.totalCompletedDays }} {{ "i18n.service-days.title" | safeTranslate }}
              <mat-icon class="tw-text-white" svgIcon="circle_info" style="margin-left: 4px" />
            </dd>
          </div>
        </dl>
      </div>
    </msa-card>
  } @else if (serviceData.isLeader) {
    <msa-card
      [footNote]="serviceData.isSpecialist ? 'i18n.service-days.footer-note-body' : ''"
      [footNoteBody]="serviceData.isSpecialist ? 'i18n.service-days.footer-note-title' : ''"
      cardTitle="i18n.service-days.title"
    >
      <div class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 [&_*]:tw-border-black-light-3">
        <!-- Left Column -->
        <dl class="msa-text tw-flex tw-flex-col d:tw-me-30 d:tw-gap-y-18">
          <h3 class="msa-text-h3 max-d:tw-pb-12 max-d:tw-pt-12">
            {{ "i18n.service-days.totalMilitaryCareer" | safeTranslate }}
          </h3>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.servedDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.servedDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.totalCompletedDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2 tw-flex tw-items-center" data-cy="function">
              {{ serviceData.totalCompletedDays }} {{ "i18n.service-days.title" | safeTranslate }}
              <mat-icon class="tw-text-white" svgIcon="circle_info" style="margin-left: 4px" />
            </dd>
          </div>
        </dl>
        <!-- Right Column -->
        <dl class="msa-text tw-flex tw-flex-col d:tw-ms-30 d:tw-gap-18">
          <h3 class="msa-text-h3 max-d:tw-pb-12 max-d:tw-pt-12">
            {{ "i18n.service-days.currentMilitaryCareer" | safeTranslate }}
          </h3>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.totalDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.totalDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.completedDays2" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2 tw-flex tw-items-center" data-cy="function">
              {{ serviceData.completedDays }} {{ "i18n.service-days.title" | safeTranslate }}
              <mat-icon class="tw-text-white" svgIcon="circle_info" style="margin-left: 4px" />
            </dd>
          </div>

          <div class="tw-grid tw-grid-cols-1 max-d:tw-gap-y-9 d:tw-grid-cols-3 d:tw-gap-x-30">
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.remainingDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.remainingDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
        </dl>
      </div>
    </msa-card>
  } @else if (!serviceData.isLeader) {
    <msa-card
      [footNote]="serviceData.isSpecialist ? 'i18n.service-days.footer-note-body' : ''"
      [footNoteBody]="serviceData.isSpecialist ? 'i18n.service-days.footer-note-title' : ''"
      cardTitle="i18n.service-days.title"
    >
      <div class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 [&_*]:tw-border-black-light-3">
        <dl class="msa-text tw-flex tw-flex-col d:tw-me-30 d:tw-gap-y-18">
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.totalDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.totalDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.servedDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.servedDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.completedDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2 tw-flex tw-items-center" data-cy="function">
              {{ serviceData.completedDays }} {{ "i18n.service-days.title" | safeTranslate }}
              <mat-icon class="tw-text-white" svgIcon="circle_info" style="margin-left: 4px" />
            </dd>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
          >
            <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
              {{ "i18n.service-days.remainingDays" | safeTranslate }}
            </dt>
            <dd class="tw-col-span-2" data-cy="function">
              {{ serviceData.remainingDays }} {{ "i18n.service-days.title" | safeTranslate }}
            </dd>
          </div>
        </dl>
      </div>
    </msa-card>
  }
}
