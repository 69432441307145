import {ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {TranslateModule} from '@ngx-translate/core';
import {MilitaryRestService, PersonMilitaryServiceDaysExtendedDto} from '../../core/api/generated/msa-person-data';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MsaCardComponent,
    SafeTranslatePipe,
    TranslateObjectPipe,
    StatusPillComponent,
    TranslateModule
  ],
  selector: 'msa-service-days',
  standalone: true,
  templateUrl: './service-days.component.html'
})
export class ServiceDaysComponent implements OnInit {
  private militaryDataRestService: MilitaryRestService = inject(MilitaryRestService);
  serviceDaysData: WritableSignal<PersonMilitaryServiceDaysExtendedDto> = signal({});

  ngOnInit() {
    this.militaryDataRestService.getPersonMilitaryServiceDays().subscribe(result => {
      this.serviceDaysData.set(result);
    });
  }
}
